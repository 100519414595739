@font-face {
    font-family: 'GT Walsheim Pro Bold';
    src: url('../fonts/GTWalsheimPro-Bold.eot');
    src: url('../fonts/GTWalsheimPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GTWalsheimPro-Bold.woff2') format('woff2'), url('../fonts/GTWalsheimPro-Bold.woff') format('woff'),
        url('../fonts/GTWalsheimPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Spartan MB Light';
    src: url('../fonts/SpartanMB-Light.eot');
    src: url('../fonts/SpartanMB-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpartanMB-Light.woff2') format('woff2'), url('../fonts/SpartanMB-Light.woff') format('woff'),
        url('../fonts/SpartanMB-Light.ttf') format('truetype'),
        url('../fonts/SpartanMB-Light.svg#SpartanMB-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Spartan MB Regular';
    src: url('../fonts/SpartanMB-Regular.eot');
    src: url('../fonts/SpartanMB-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpartanMB-Regular.woff2') format('woff2'), url('../fonts/SpartanMB-Regular.woff') format('woff'),
        url('../fonts/SpartanMB-Regular.ttf') format('truetype'),
        url('../fonts/SpartanMB-Regular.svg#SpartanMB-Regular') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan MB Bold';
    src: url('../fonts/SpartanMB-Bold.eot');
    src: url('../fonts/SpartanMB-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpartanMB-Bold.woff2') format('woff2'), url('../fonts/SpartanMB-Light.woff') format('woff'),
        url('../fonts/SpartanMB-Bold.ttf') format('truetype'),
        url('../fonts/SpartanMB-Bold.svg#SpartanMB-Bold') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Spartan MB Black';
    src: url('../fonts/SpartanMB-Black.eot');
    src: url('../fonts/SpartanMB-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpartanMB-Black.woff2') format('woff2'), url('../fonts/SpartanMB-Black.woff') format('woff'),
        url('../fonts/SpartanMB-Black.ttf') format('truetype'),
        url('../fonts/SpartanMB-Black.svg#SpartanMB-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}
